import React, { useState } from 'react'
import moment from 'moment'
import useCarrier from './hooks/useCarrier'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Loading from './Components/Loading'
import Errors from './Components/Errors'
import DeliveryStatus from './Components/DeliveryStatus'
import PickupStatus from './Components/PickupStatus'
import OpenApptWindowsView from './Components/OpenApptWindowsView/OpenApptWindowsView'
import ReferenceNumbersCustomFields from './Components/ReferenceNumbersCustomFields'
// import ShipmentDetails from '../ShipmentDetails/ShipmentDetails'
import ShipperDocs from './Components/ShipperDocs'
import ConsigneeDocs from './Components/ConsigneeDocs'
import AdminDatePicker from './Components/AdminDatePicker/AdminDatePicker'
import KwikTripConsigneeLocationInfo from './Components/KwikTripConsigneeLocationInfo'
import DropLiveShipment from '../CommonSmallComponents/DropLiveShipment'
import { UserControl } from './Components/UserControl/UserControl'
import { ShipmentDetails2 } from '../ShipmentDetails/ShipmentDetails2'

const Carrier = (props) => {


    const {
        pageMode,
        errorText,
        driverCode,
        shipment,
        setPickupOrDelivery,
        pickupOrDelivery,
        pickupRequired,
        deliveryRequired,
        // locationInfo,
        // startHour, 
        // endHour, 
        // delMinMaxSelectedDates, 
        // shipMinMaxSelectedDates, 
        existingPickupEvent,
        existingDeliveryEvent,
        getDataNew,
        dockEventsPickup,
        dockEventsDelivery,
        shipperLocationInfo,
        consigneeLocationInfo,
        openTimeWindows,
        adminDate,
        setAdminDate,
        consolidatedOpenTimeWindows
    } = useCarrier(props)

    const [appointmentBeingScheduled, setAppointmentBeingScheduled] = useState(false)
    const [crudActionInProgress, setCrudActionInProgress] = useState(false)

    // This is a switch to turn on window scheduling vs calendar.    At this point nobody uses calendar scheduling for the carriers but leaving this here just in case we need to switch back fast.

    let viewMode = "openWindow"


    // Updates the ui on the card that indicates if a shipment is scheduled or not.
    const handleApptBeingScheduled = (pickupOrDelivery) => {
        setAppointmentBeingScheduled(pickupOrDelivery)
    }

    const handleReload = () => {
        window.location.reload(true);
    };

    if (pageMode === 'loading') {
        return (
            <Loading />
        )
    } else if (pageMode === 'no appointment accessorials') {
        return (
            <Errors pageMode={pageMode} driverCode={driverCode} />
        )
    } else if (pageMode === 'error no shipment found') {
        return (
            <Errors pageMode={pageMode} driverCode={driverCode} />
        )

    } else if (pageMode === 'error') {
        return (
            <Errors pageMode={pageMode} driverCode={driverCode} errorText={errorText} />
        )
    } else if (dockEventsPickup || dockEventsDelivery) {

        let showPickupInfo = true
        if (shipment.client.id === "kwiktrip") {
            showPickupInfo = false
        }

        if (props.isConsolidation && !pickupOrDelivery) {
            return null
        }

        return (
            < Container >
                {/* <CarrierNavBar locationInfo={locationInfo} /> */}
                <Row>
                    <Card style={{ width: '100rem', margin: '0', padding: '0' }} >
                        <Card.Body style={{ margin: '0', padding: '0' }}>
                            <Row className="row-divided" style={{ margin: '0', padding: '0' }}>
                                <Col md={4} xs={12} style={{
                                    padding: '2rem', backgroundColor: '#F8F8F8', textAlign: 'left'
                                }}>
                                    <h3 style={{ textAlign: 'left' }}>
                                        {/* {!props.isConsolidation ? <>Shipment</> : <>Stop: {props.stopNumber + 1}</>} */}
                                    </h3>
                                    <Row>
                                        <Col>
                                            {!props.isConsolidation ? <>BOL #: {shipment.id} </> : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5>PO/SO #:</h5><UserControl shipment={shipment} pickupOrDelivery={pickupOrDelivery} />
                                        </Col>
                                    </Row>
                                    {shipment.client.id === "kwiktrip" ?
                                        <KwikTripConsigneeLocationInfo consigneeLocationInfo={consigneeLocationInfo} />
                                        : null}
                                    <ReferenceNumbersCustomFields shipment={shipment} />
                                    {showPickupInfo ?
                                        <>
                                            <Row><Col><h4>Pickup</h4></Col></Row>
                                            <Row>
                                                <Col>
                                                    <h5 style={{ marginBottom: '0' }}>Pickup:
                                                        {pickupRequired && deliveryRequired ? (<small>&nbsp;<a href="javascript:;" onClick={() => setPickupOrDelivery('Pickup')}>Select Appointment</a></small>) : null}
                                                    </h5>
                                                    <PickupStatus
                                                        existingPickupEvent={existingPickupEvent}
                                                        pickupRequired={pickupRequired}
                                                        getDataNew={getDataNew}
                                                        shipperLocationInfo={shipperLocationInfo}
                                                        appointmentBeingScheduled={appointmentBeingScheduled}
                                                        crudActionInProgress={crudActionInProgress}
                                                        setCrudActionInProgress={setCrudActionInProgress}
                                                    />
                                                    {pickupRequired ?
                                                        <div>
                                                            {shipment.shipper?.dates.available1 ? shipment.shipper?.dates.available1 === shipment.shipper?.dates.available2 ? 'Available Date: ' + moment(shipment.shipper?.dates.available1).format("MMM D, YY") : 'Available Dates: ' + moment(shipment.shipper?.dates.available1).format("MMM D, YYYY") + ' - ' + moment(shipment.shipper?.dates.available2).format("MMM D, YYYY") : ''}
                                                        </div>
                                                        : <div>
                                                            {shipment.shipper?.dates.available1 ? shipment.shipper?.dates.available1 === shipment.shipper?.dates.available2 ? moment(shipment.shipper?.dates.available1).format("MMM D, YY") : moment(shipment.shipper?.dates.available1).format("MMM D, YYYY") + ' - ' + moment(shipment.shipper?.dates.available2).format("MMM D, YYYY") : ''}
                                                        </div>
                                                    }

                                                    <div className="mt-2">{shipment.shipper?.name}</div>
                                                    <div>{shipment.shipper?.address?.line1} {shipment.shipper?.address?.line2}</div>
                                                    <div>{shipment.shipper?.address?.city} {shipment.shipper?.address?.state} {shipment.shipper?.address?.zip}</div>
                                                    {/* 
                                            {shipment.shipper?.bolNotes && shipment.shipper?.bolNotes !== "" ?
                                                <div className="mt-2">{shipment.shipper.bolNotes}</div>

                                                : null} */}
                                                    <div className="mt-2">{shipment.shipper?.contact?.name}</div>
                                                    <div><a href={`tel: ${shipment.shipper?.contact?.phone}`}>{shipment.shipper?.contact?.phone}</a></div>
                                                    <div><a href={`mailto:${shipment.shipper?.contact?.email}`}>{shipment.shipper?.contact?.email}</a></div>
                                                    <div className='mt-2'>
                                                        <ShipperDocs shipment={shipment} />
                                                    </div>

                                                </Col>
                                            </Row>
                                            <Row><Col>&nbsp;</Col></Row>
                                            {pickupRequired && !deliveryRequired ?
                                                <Row>
                                                    <Col>
                                                        <ShipmentDetails2 shipment={shipment} isConsolidation={props.isConsolidation} pickupOrDelivery={'Pickup'} />
                                                    </Col>
                                                </Row>
                                                : null}
                                        </>
                                        : null}

                                    {/* Quick update */}

                                    <Row><Col>&nbsp;</Col></Row>
                                    <Row>
                                        <Col>
                                            <h5 style={{ marginBottom: '0' }}>Delivery:
                                                {deliveryRequired && pickupRequired ? (<small>&nbsp;<a href="javascript:;" onClick={() => setPickupOrDelivery('Delivery')}>Select Appointment</a></small>) : null}
                                            </h5>

                                            {shipment.client.id === "kwiktrip" || shipment.client.id === 'sleepcountry' ?
                                                <DropLiveShipment role="carrier" shipment={shipment} />
                                                : null}

                                            <DeliveryStatus
                                                deliveryRequired={deliveryRequired}
                                                existingDeliveryEvent={existingDeliveryEvent}
                                                getDataNew={getDataNew}
                                                consigneeLocationInfo={consigneeLocationInfo}
                                                appointmentBeingScheduled={appointmentBeingScheduled}
                                                crudActionInProgress={crudActionInProgress}
                                                setCrudActionInProgress={setCrudActionInProgress}
                                            />
                                            {deliveryRequired ?
                                                <>
                                                    <div>{shipment.consignee?.dates.requestedDeliveryDate ? 'Available Date: ' + moment(shipment.consignee?.dates.requestedDeliveryDate).format("MMM D, YYYY") : ''}</div>

                                                    <div>{shipment.consignee?.dates.available1 ? 'Available Dates: ' + moment(shipment.consignee?.dates.available1).format("MMM D, YYYY") + ' to ' + moment(shipment.consignee?.dates.available2).format("MMM D, YYYY") : ''}</div></>

                                                :
                                                <>

                                                    <div>{shipment.consignee?.dates.requestedDeliveryDate ? moment(shipment.consignee?.dates.requestedDeliveryDate).format("MMM D, YYYY") : ''}</div>

                                                    <div>{shipment.consignee?.dates.available1 ? moment(shipment.consignee?.dates.available1).format("MMM D, YYYY") + ' to ' + moment(shipment.consignee?.dates.available2).format("MMM D, YYYY") : ''}</div></>
                                            }

                                            <AdminDatePicker shipment={shipment} setAdminDate={setAdminDate} />

                                            <div className="mt-2">{shipment.consignee?.name}</div>
                                            <div>{shipment.consignee?.address?.line1} {shipment.consignee?.address?.line2}</div>
                                            <div>{shipment.consignee?.address?.city} {shipment.consignee?.address?.state} {shipment.consignee?.address?.zip}</div>

                                            <div className="mt-2">{shipment.consignee?.contact?.name}</div>
                                            <div><a href={`tel: ${shipment.consignee?.contact?.phone}`}> {shipment.consignee?.contact?.phone}</a></div>
                                            <div><a href={`mailto:${shipment.consignee?.contact?.email}`}>{shipment.consignee?.contact?.email}</a></div>
                                            <div className='mt-2'>
                                                <ConsigneeDocs shipment={shipment} />
                                            </div>

                                        </Col>
                                    </Row>

                                    <Row><Col>&nbsp;</Col></Row>
                                    {(!pickupRequired && deliveryRequired) || (pickupRequired && deliveryRequired) ?
                                        <Row>
                                            <Col>
                                                <ShipmentDetails2 shipment={shipment} isConsolidation={props.isConsolidation} pickupOrDelivery={'Delivery'} />
                                            </Col>
                                        </Row> : null}

                                </Col>

                                <Col md={8} xs={12}>
                                    {pickupOrDelivery === "Pickup" && dockEventsPickup ?

                                        <div style={{ overflowX: 'auto' }}>
                                            <h3 className="mt-3">Schedule {pickupOrDelivery} Appointment</h3>
                                            {/* <p>{this.state.availableDateTimeText}</p> */}
                                            {viewMode === 'openWindow' ?
                                                <OpenApptWindowsView
                                                    type={pickupOrDelivery}
                                                    openTimeWindows={openTimeWindows}
                                                    pickupOrDelivery={pickupOrDelivery}
                                                    existingEvent={existingPickupEvent}
                                                    shipment={shipment}
                                                    driverCode={driverCode}
                                                    getDataNew={getDataNew}
                                                    adminDate={adminDate}
                                                    consolidatedOpenTimeWindows={consolidatedOpenTimeWindows}
                                                    handleApptBeingScheduled={handleApptBeingScheduled}
                                                    crudActionInProgress={crudActionInProgress}
                                                    setCrudActionInProgress={setCrudActionInProgress}

                                                />
                                                : null
                                                // <CalendarView shipment={shipment} pickupOrDelivery={pickupOrDelivery} locationInfo={locationInfo} startHour={startHour} endHour={endHour} getDataNew={getDataNew} driverCode={driverCode} delMinMaxSelectedDates={delMinMaxSelectedDates} shipMinMaxSelectedDates={shipMinMaxSelectedDates} dockEvents={dockEventsPickup} />
                                            }
                                        </div> : null}


                                    {pickupOrDelivery === "Delivery" && dockEventsDelivery ?
                                        <div style={{ overflowX: 'auto' }}>
                                            <h3 className="mt-3">Schedule {pickupOrDelivery} Appointment</h3>
                                            {/* <p>{this.state.availableDateTimeText}</p> */}
                                            {viewMode === 'openWindow' ?
                                                <OpenApptWindowsView
                                                    type={pickupOrDelivery}
                                                    openTimeWindows={openTimeWindows}
                                                    pickupOrDelivery={pickupOrDelivery}
                                                    existingEvent={existingDeliveryEvent}
                                                    shipment={shipment}
                                                    driverCode={driverCode}
                                                    getDataNew={getDataNew}
                                                    consolidatedOpenTimeWindows={consolidatedOpenTimeWindows}
                                                    handleApptBeingScheduled={handleApptBeingScheduled}
                                                    crudActionInProgress={crudActionInProgress}
                                                    setCrudActionInProgress={setCrudActionInProgress}

                                                />
                                                : null
                                                // <CalendarView shipment={shipment} pickupOrDelivery={pickupOrDelivery} locationInfo={locationInfo} startHour={startHour} endHour={endHour} getDataNew={getDataNew} driverCode={driverCode} delMinMaxSelectedDates={delMinMaxSelectedDates} shipMinMaxSelectedDates={shipMinMaxSelectedDates} dockEvents={dockEventsDelivery} />
                                            }
                                        </div>
                                        : null}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
                <Modal
                    // show={this.state.showModal} onHide={this.handleClose} 
                    centered>
                    <Modal.Header closeButton>
                        <h4>Please Confirm {pickupOrDelivery} Appointment</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <p><h6>{pickupOrDelivery} Appointment Selected:</h6></p>
                        <p>
                            {/* Date: {moment(this.state.appointmentSelected.start).format("dddd, MMMM Do YYYY")} */}
                            <br />
                            Arrival:
                            {/* {moment(this.state.appointmentSelected.start).format("h:mm A")} - Departure: {moment(this.state.appointmentSelected.end).format("h:mm A")} */}
                        </p>
                        <p>Address:
                            <br />
                            {pickupOrDelivery === 'Pickup' ? shipment.shipper?.name : shipment.consignee?.name}
                            <br />
                            {shipment.shipper?.address?.line1} {shipment.shipper?.address?.line2}
                            <br />
                            {shipment.shipper?.address?.city}, {shipment.shipper?.address?.state} {shipment.shipper?.address?.zip}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button variant="outline-secondary" onClick={this.handleClose}>Cancel</Button>
                        <Button variant="secondary" onClick={this.saveAppointment}>Confirm</Button> */}
                    </Modal.Footer>
                </Modal>

            </Container >
        )
    } else {
        return (
            <div className="container d-flex justify-content-center">
                <div className="alert alert-warning mt-3" role="alert">
                    An error has occured.<br />
                    Please contact support or try reloading the page.<br />

                    <button className="btn btn-primary" onClick={handleReload}>Click to reload the page.</button>

                    <br />
                    Error: Missing dockEvents.
                </div>
            </div>
        )
    }
}

export default Carrier
