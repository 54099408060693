import React, { useState, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'

const ApptOptionTwo = ({ key, type, option, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled, hilight, crudActionInProgress, setCrudActionInProgress }) => {
    // console.log('ApptOptionTwo', adminDate, option, existingEvent, shipment)

    let displayDockname = false
    let displayDurration = false

    const [status, setStatus] = useState('start')
    const [fontClass, setFontClass] = useState('text-dark')
    const [divColorClass, setDivColorClass] = useState('bg-info')

    useEffect(() => {
        if (status === 'start') {
            setFontClass('text-dark')
            setDivColorClass('bg-light')
        }
        if (status === 'scheduling') {
            setFontClass('text-white')
            setDivColorClass('bg-info')
        }
        if (status === 'success') {
            setFontClass('text-white')
            setDivColorClass('bg-success')
        }
        if (status === 'windowNotAvailable') {
            setFontClass('text-white')
            setDivColorClass('bg-warning')
        }
    }, [status])


    const scheduleAppointment = (type, option, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled, crudActionInProgress, setCrudActionInProgress) => {
        if (crudActionInProgress) { return false }
        setCrudActionInProgress(true)
        let requestType
        if (existingEvent.length === 0) {
            requestType = 'eventCreate'
        } else {
            requestType = 'eventChange'
        }
        let shipmentId = shipment.id
        let dockId = option.dock
        let timeIn = option.start
        let timeOut = option.end
        let puOrDel = type

        let body = {
            requestType: requestType,
            shipmentId: shipmentId,
            responsibleUser: 'carrier',
            carrierRequestVersion: 2,
            dockId: dockId,
            timeIn: timeIn,
            timeOut: timeOut,
            type: puOrDel
        }

        if (adminDate && adminDate !== "") {
            body.adminDate = adminDate
        }

        // console.log('scheduleAppointment body', body)
        // return true
        setStatus('scheduling')
        axios.post(`https://api.shippersedge.com/schedule/shipment/`, body, { headers: { 'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW' } })
            .then(response => {
                // Using timeouts and a window reload here as we've seen some delays in rendering the new data.  
                // console.log('response to scheduling', response)
                setTimeout(() => {
                    if (response?.status === 200) {
                        setCrudActionInProgress(false)
                        // getDataNew(driverCode)
                        setStatus('success')
                        setTimeout(() => {
                            handleApptBeingScheduled(puOrDel)
                            getDataNew(driverCode).then(() => {
                                handleApptBeingScheduled(false)
                            })
                        }, 500)
                    } else if (response?.status === 'failed' && response?.message === "window is not available") {
                        setCrudActionInProgress(false)
                        setStatus('windowNotAvailable')
                    }
                }, 2000)
            })
    }


    const ScheduleStatus = () => {
        if (status === 'start') {
            return (
                null
            )
        }
        if (status === 'scheduling') {
            return (
                <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </>
            )
        }
        if (status === 'success') {
            return (
                <span>
                    Scheduled!
                </span>)

        } if (status === 'windowNotAvailable') {
            return (
                <span>
                    Sorry that appt is no longer available.
                </span>)
        } else {
            return <span>Button error, oh noes!</span>
        }
    }

    const yellowBorderHilight = {
        boxShadow: '0 0 0 5px rgb(255, 188, 44)',  // A solid blue shadow
    };

    if (option) {
        return (
            <div

                className={`mt-3 mr-4 border border-primary rounded-3 p-3 ${fontClass} ${divColorClass}`}
                style={{ width: "140px", cursor: 'pointer', ...(hilight ? yellowBorderHilight : {}) }}

                onClick={() => scheduleAppointment(type, option, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled, crudActionInProgress, setCrudActionInProgress)}
            >
                <h5 className="card-title">{moment(option.start.slice(0, -5)).format('LT')}  </h5>
                {option.duration}<br />
                <ScheduleStatus />
            </div >
        )
    } else {
        return (<div>ApptOption error</div>)
    }
}

export default ApptOptionTwo