import React, { useState, useEffect } from 'react'

const UnapprovedAppts = () => {

    const [unapprovedAppts, setUnapprovedAppts] = useState([])

    useEffect(() => {
        getUnApprovedAppts()
    }, [])


    const getUnApprovedAppts = async () => {

        let locationId
        try {
            locationId = window.location.pathname.match(/\/manager\/(\d+)/)?.[1]

        } catch (error) {
            console.log('Error finding locationId', error)
            setUnapprovedAppts([])
            return
        }
        let url = `https://api.shippersedge.com/schedule/dockEventsV2/${locationId}?unapproved=true`
        let myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW")

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }

        let response = await fetch(url, requestOptions)
        let result = await response.json()
        let sortedResult = result.sort((a, b) => new Date(a.timeIn) - new Date(b.timeIn))
        setUnapprovedAppts(sortedResult)
    }

    return (
        <div>
            <h1 className=''>Unapproved Appointments</h1>
            {/* alert secondary */}
            <div className='d-flex flex-row justify-content-center'>
                <p>Appointments requested, but not yet approved.</p>
            </div>
            <div className='d-flex flex-row flex-wrap mt-3'>
                {unapprovedAppts.map((appt, index) => {
                    let humanReadableTimeIn = new Date(appt.timeIn).toLocaleString()
                    let humanReadableTimeOut = new Date(appt.timeOut).toLocaleString()
                    let last11CharOfHumanReadableTimeOut = humanReadableTimeOut.slice(-11)
                    let loadTypeCapsFirstLetter
                    if (appt?.loadType) {
                        loadTypeCapsFirstLetter = appt.loadType.charAt(0).toUpperCase() + appt.loadType.slice(1)
                    }
                    return (
                        <div key={index} className='card me-3' style={{ width: '31%' }} >
                            <div className='card-header'>
                                <h3>{appt.usercontrol}</h3>
                            </div>
                            <div className='card-body'>
                                <div className='card-text text-start'>
                                    <p><strong>{appt.supplierName} </strong></p>
                                    {appt.carrierName ? <p>{appt.carrierName}</p> : null}
                                    {appt?.consolidationCarrierName ? <p>{appt.consolidationCarrierName}</p> : null}
                                    <p>{loadTypeCapsFirstLetter}</p>
                                    <p>{humanReadableTimeIn} - {last11CharOfHumanReadableTimeOut}</p>
                                </div>
                            </div>
                        </div>

                    )

                })}
            </div>
        </div>
    )
}

export default UnapprovedAppts