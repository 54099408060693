import React, { useState, useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'

const ApprovedAppt = ({ role, appointment }) => {
    const [status, setStatus] = useState('start')
    const [approved, setApproved] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (appointment?.approved !== undefined) {
            setApproved(appointment.approved)
        }
    }, [appointment])

    const handleApprovedClick = (choice) => {
        if (loading || approved === choice) return  // Prevent re-clicks & multiple submissions

        setStatus('loading')
        setLoading(true)
        setApproved(choice)

        updateApprovedViaDSBackEnd(appointment.id, choice)
    }

    const updateApprovedViaDSBackEnd = async (apptID, choice) => {
        let url = `https://api.shippersedge.com/schedule/apptApprovedStatus/${apptID}/${choice}`

        try {
            let response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'x-api-Key': 'vdhy8wwjUJrwhxFpfuECTiAW'
                }
            })
            let data = await response.json()

            if (response.status === 200) {
                setStatus('success')
            } else {
                setStatus('error')
            }
        } catch (error) {
            setStatus('error')
        } finally {
            setLoading(false)
        }
    }

    const StatusPanel = () => {
        if (status === 'start' && role === 'carrier') return null

        if (status === 'error') {
            return <div className='text-danger'>Error</div>
        }
        if (status === 'success' && role === 'carrier') {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="green" className="bi bi-check2-circle" viewBox="0 0 16 16">
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg>
            )
        }
        return null
    }

    if (!role || !appointment) return null

    let disableFade = role === 'carrier'

    return (
        <Fade disabled={disableFade}>
            <div id="approvedAppt" className='card mt-2 d-flex flex-row ms-2 position-relative' style={{ minHeight: '60px' }}>
                {/* Full-page overlay spinner while loading */}
                {loading && (
                    <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-75" style={{ top: 0, left: 0, zIndex: 10 }}>
                        <div className="spinner-border text-primary" role="status" style={{ width: '2rem', height: '2rem' }}>
                        </div>
                    </div>
                )}

                <div id="approvedApptInfo" className="card-body d-flex flex-column justify-content-center" style={{ pointerEvents: loading ? 'none' : 'auto' }}>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="approvedApptRadio"
                            id="approvedApptRadio"
                            checked={approved === 1}
                            disabled={loading}
                            onClick={() => handleApprovedClick(1)}
                        />
                        <label className="form-check-label" htmlFor="approvedApptRadio">
                            Approved
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="notApprovedRadio"
                            id="notApprovedRadio"
                            checked={approved === 0}
                            disabled={loading}
                            onClick={() => handleApprovedClick(0)}
                        />
                        <label className="form-check-label" htmlFor="notApprovedRadio">
                            Not Approved
                        </label>
                    </div>
                </div>

                <div id="notApprovedRadioStatus" className='d-flex flex-row justify-content-center align-items-center flex-grow-1'>
                    <StatusPanel />
                </div>
            </div>
        </Fade>
    )
}

export default ApprovedAppt