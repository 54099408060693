import React, { useState, useEffect } from 'react'

const Colors = ({ shipment, appointmentProps }) => {
    const [color, setColor] = useState(null)
    const [status, setStatus] = useState('start') // 'start', 'loading', 'error'
    const [loading, setLoading] = useState(false)

    const colorAndNames = [
        { HTML_Color: null, name: 'No Color' },
        { HTML_Color: 'FE6544', name: 'Late' },
        { HTML_Color: '58D68D', name: 'Unloaded' }
    ]

    useEffect(() => {
        if (appointmentProps?.dayCustomColor) {
            let colorAndName = colorAndNames.find(cn => cn.HTML_Color === appointmentProps.dayCustomColor) || colorAndNames[0]
            setColor(colorAndName)
        }
    }, [appointmentProps])

    const handleColorClick = (HTML_Color) => {
        if (loading || color?.HTML_Color === HTML_Color) return // Prevent redundant clicks

        setStatus('loading')
        setLoading(true)
        setColor(colorAndNames.find(cn => cn.HTML_Color === HTML_Color))

        saveColor(HTML_Color)
    }

    const saveColor = async (HTML_Color) => {
        let body = {
            appointmentId: appointmentProps.id,
            color: HTML_Color
        }
        let url = `https://api.shippersedge.com/schedule/colorCategories/`

        try {
            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'x-api-Key': 'vdhy8wwjUJrwhxFpfuECTiAW'
                },
                body: JSON.stringify(body)
            })

            let data = await response.json()

            if (response.status === 200 && data) {
                setStatus('start') // Reset status to default after success
            } else {
                setStatus('error')
            }
        } catch (error) {
            setStatus('error')
        } finally {
            setLoading(false)
        }
    }

    const ErrorPanel = () => {
        if (status === 'error') {
            return (
                <div className='text-danger'>
                    <h5>Error!</h5>
                </div>
            )
        }
        return null
    }

    return (
        <div className="card ms-2 mt-2 pe-2 position-relative">
            {/* Overlay Spinner During Request */}
            {loading && (
                <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-75" style={{ top: 0, left: 0, zIndex: 10 }}>
                    <div className="spinner-border text-primary" role="status" style={{ width: '2rem', height: '2rem' }}>
                    </div>
                </div>
            )}

            {/* Radio buttons generated by colorAndNames */}
            <div className="form-check mt-2" style={{ pointerEvents: loading ? 'none' : 'auto' }}>
                {colorAndNames.map((colorAndName, index) => (
                    <div className="form-check" key={index}>
                        <input
                            className="form-check-input e-field"
                            type="radio"
                            name="apptColor"
                            id={colorAndName.name}
                            checked={color?.HTML_Color === colorAndName.HTML_Color}
                            disabled={loading}
                            onClick={() => handleColorClick(colorAndName.HTML_Color)}
                        />
                        <label className="form-check-label d-flex flex-row align-items-center" htmlFor={colorAndName.name}>
                            {colorAndName.name}
                            <div className="ms-3 border border-black" style={{ width: 20, height: 20, backgroundColor: `#${colorAndName.HTML_Color}` }} />
                        </label>
                    </div>
                ))}
            </div>

            {/* Error Panel */}
            <div className="d-flex flex-row justify-content-center align-items-center mt-2">
                <ErrorPanel />
            </div>
        </div>
    )
}

export default Colors